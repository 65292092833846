<template>
    <form @submit.prevent="handleRegister()">
        <div class="error-msg" v-if="error">
            <p>{{ errorMsg }}</p>
        </div>
        <div class="form-row">
            <span class="field-wrapper email">
                <label class="top-level" for="user-register-email">E-mail</label>
                <div class="input-wrapper">
                    <input type="email" id="user-register-email" name="username" placeholder="E-mail" @keyup="changeInput()"
                        v-model="user.username" :class="{ error: error }" />
                </div>
            </span>
        </div>

        <div class="form-row">
            <span class="field-wrapper password">
                <label class="top-level" for="user-register-password">Password</label>
                <div class="input-wrapper">
                    <input id="user-register-password" name="password" type="password" placeholder="Password"
                        @keyup="changeInput()" v-model="user.password" :class="{ error: error }" />
                </div>
            </span>
        </div>

        <div class="form-row">
            <span class="field-wrapper checkbox" :class="{ error: privacyPolicyError }">
                <div class="input-wrapper">
                    <input id="user-register-privacy-policy" name="user-register-privacy-policy" type="checkbox" required
                        v-model="user.checkedPrivacy" />
                    <label for="user-register-privacy-policy">I agree with the privacy policy</label>
                </div>
            </span>
        </div>

        <button class="submit" data-txt="Next step">Next step</button>
    </form>
</template>

<script>
import { registerUser } from "@streampac.io/chef_sp_1";

export default {
    inject: ["setAuthenticated"],
    name: "RegisterForm",
    components: {},
    data() {
        return {
            user: {
                username: "",
                password: "",
                checkedPrivacy: false,
            },
            privacyPolicyError: false,
            errorMsg: "",
            error: false,
        };
    },
    created() { },
    methods: {
        changeInput() {
            //this.error = '';
        },
        handleRegister() {
            if (
                this.user.username != "" &&
                this.user.password != "" &&
                this.user.checkedPrivacy === true
            ) {
                registerUser(
                    this.user.username,
                    this.user.password,
                    "User",
                    (response) => {
                        if (response.resultCode === 1000) {
                            this.setAuthenticated(true);
                            this.error = false;

                            if (
                                this.$route.params &&
                                this.$route.params.referer &&
                                this.$route.params.referer !== ""
                            ) {
                                this.$router.push({
                                    name: this.$route.params.referer,
                                    query: { "just-registered": "true" },
                                });
                            } else {
                                this.$router.push({
                                    name: "Pricing",
                                    query: { "just-registered": "true" },
                                });
                            }
                        } else if (response.resultCode === 2003) {
                            this.error = true;
                            this.errorMsg = "E-mail has already been taken";
                        } else {
                            this.error = true;
                            this.errorMsg = "Something went wrong";
                        }
                    }
                );
                this.error = false;
                this.privacyPolicyError = false;
                this.errorMsg = "";
            } else if (this.user.checkedPrivacy === false) {
                this.error = true;
                this.privacyPolicyError = true;
                this.errorMsg =
                    "Please make sure all fields are filled in correctly";
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>

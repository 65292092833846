<template>
    <!-- <section class="section no-header">
        <div class="container">
            <div class="column-wrapper">
                <div class="column intro-column">
                    <h1 class="block-title">
                        Get started
                    </h1>
                    <span class="block-subtitle">Step 1/3</span>
                </div>
            </div>
        </div>
    </section> -->

    <HeroPage v-if="pageData" :data="pageData" heroType="center" />

    <section class="sign-up-section">
        <div class="inner-wrapper">
            <div class="container">
                <div class="column-wrapper fullwidth">
                    <div class="column form">
                        <div class="title-wrapper">
                            <h2 class="column-title">Create your account</h2>
                            <p class="column-subtitle">
                                You're almost there
                            </p>
                        </div>
                        <RegisterForm />
                        <router-link class="close-btn" to="/"></router-link>
                    </div>
                    <div class="account-login-mini-bar">
                        Already have an account?
                        <button type="button" class="text-btn" @click="showLoginPopup()">
                            Login
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
//import { fetchPageBySlug } from "@streampac.io/chef_sp_1";

// @import component
import RegisterForm from "@/components/Forms/RegisterForm";
import HeroPage from "@/components/HeroPage";

export default {
    name: "theRegisterPage",
    inject: ["isAuthenticated"],
    data() {
        return {
            //pageData: null,
            pageData: {
                headerTitle: 'Get started',
                headerSubtitle: 'Step 1/3'
            }
        };
    },
    components: {
        HeroPage,
        RegisterForm,
    },
    created() {
        //this.fetchPage();
    },
    methods: {
        // fetchPage() {
        // 	fetchPageBySlug(this.isAuthenticated, "sign-up").then((response) => {
        // 		this.pageData = response;
        // 	});
        // },
        showLoginPopup() {
            this.$store.commit("toggleLoginPopup");
        },
    },
};
</script>

<style lang="scss" scoped>
// @import styles
@import "./index.scss";
</style>
